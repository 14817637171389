export type ErrorMap = {
  [key in FormErrorType]?: ErrorMessage | ErrorMessageProducer | true;
};

export type ErrorMessage = string | undefined;

export type ErrorMessageProducer = (value: string) => ErrorMessage;

export type FormErrors = Record<string, ErrorMessage>;

export type FormErrorMessages = Record<string, ErrorMap>;

export enum FormErrorType {
  PatternMismatch = 'patternMismatch',
  RangeOverflow = 'rangeOverflow',
  RangeUnderflow = 'rangeUnderflow',
  StepMismatch = 'stepMismatch',
  TooLong = 'tooLong',
  TooShort = 'tooShort',
  TypeMismatch = 'typeMismatch',
  Valid = 'valid',
  ValueMissing = 'valueMissing',
}
