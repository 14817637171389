import { ref } from 'vue';

import { FormErrorMessages, FormErrors } from './types';

export const useFormErrors = (messages: FormErrorMessages) => {
  const errors = ref<FormErrors>({});
  const errorMessages = ref<FormErrorMessages>({ ...messages });

  const setErrors = (value: FormErrors) => {
    errors.value = {
      ...value,
    };
  };

  const useError = (key: string, action: (error: string) => any) => {
    if (errors.value[key]) {
      return action(errors.value[key] as string);
    }
  };

  return {
    errors,
    errorMessages,
    setErrors,
    useError,
  };
};
