import { onMounted, onUnmounted } from 'vue';

export function useViewHeight() {
  const events = ['resize', 'orientationchange'];
  let updateVhUnitTimeout: undefined | ReturnType<typeof setTimeout> = undefined;

  const setVhUnit = () => {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`);
  };

  const updateVhUnit = () => {
    clearTimeout(updateVhUnitTimeout);
    setVhUnit();
    updateVhUnitTimeout = setTimeout(() => setVhUnit(), 500);
  };

  const toggleListeners = (on: boolean) => {
    for (const event of events) {
      window[on ? 'addEventListener' : 'removeEventListener'](event, updateVhUnit);
    }
  };

  onMounted(() => {
    setVhUnit();
    toggleListeners(true);
  });
  onUnmounted(() => toggleListeners(false));
}
